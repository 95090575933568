<template>
    <div class="nav-item" data-cursor-target data-cursor-size="lg">
        <router-link :to="{ path: url }" class="nav-link">
            <slot></slot>
        </router-link>
    </div>
</template>

<script>
export default {
    name: 'NavItem',
    props: {
        url: {
            type: String,
        },
    },
};
</script>

<style lang="scss" scoped>
.nav-item {
    margin: 0 2rem;

    .nav-link {
        text-transform: uppercase;
        color: $text-dark;
        font-size: 0.875rem;
        font-weight: 600;
        position: relative;

        &::before {
            content: '';
            width: 0;
            height: 1px;
            background-color: $secondary-color;
            position: absolute;
            bottom: -4px;
            left: 0;
            transition: 500ms cubic-bezier(0.3, 1, 0.7, 1);
        }

        &:hover,
        &:focus,
        &.router-link-exact-active {
            &::before {
                width: 60%;
            }
        }
    }
}
</style>
