<template>
  <div :class="getMenuOpened ? 'mobile-nav opened' : 'mobile-nav'" ref="menu">
    <div class="strips">
      <div class="strip"></div>
      <div class="strip"></div>
      <!-- <div class="strip"></div> -->
    </div>
    <div class="menu-container">
      <div class="content">
        <div class="logo">
          <Logo type="white" />
        </div>
        <nav class="main-nav">
          <div class="nav-item">
            <div class="nav-item__wrapper">
              <div class="title">
                <router-link to="/about-us">About Us</router-link>
              </div>
            </div>
          </div>
          <div class="nav-item">
            <div class="nav-item__wrapper">
              <div class="title">
                <router-link to="/technology">Technology</router-link>
              </div>
            </div>
          </div>
          <!-- <div class="nav-item">
                        <div class="nav-item__wrapper">
                            <div class="title">
                                <router-link to="/blog">Blog</router-link>
                            </div>
                        </div>
                    </div> -->
          <div class="nav-item">
            <div class="nav-item__wrapper">
              <div class="title">
                <router-link to="/careers">Careers</router-link>
              </div>
            </div>
          </div>
          <div class="nav-item">
            <div class="nav-item__wrapper">
              <div class="title">
                <router-link to="/contact">Contact</router-link>
              </div>
            </div>
          </div>
        </nav>
        <div class="contact-info">
          <h3 class="title">GET IN TOUCH</h3>
          <div class="details">
            <div class="contact">
              <a href="tel:+919825894705" class="phone">
                <img src="@/assets/images/icon-phone.svg" alt="" />
                +91 982-589-4705
              </a>
              <a href="mailto:info@avdevs.com" class="email">
                <img src="@/assets/images/icon-mail.svg" alt="" />
                info@avdevs.com
              </a>
            </div>
          </div>
        </div>
        <div class="bottom-line">
          <div class="bottom-line__wrapper">
            <div class="socials">
              <div class="social">
                <a href="">
                  <img src="@/assets/images/icon-linkedin.svg" alt="Linkedin" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Logo from "@/components/utils/Logo.vue";

export default {
  name: "MobileMenu",
  components: { Logo },
  computed: {
    ...mapGetters(["getMenuOpened", "getMobile"]),
  },
  methods: {
    ...mapActions(["toggleMenu"]),
    fadeInUp(el, fromY, toY, duration, delay) {
      this.gsap.fromTo(
        el,
        {
          y: fromY,
          opacity: 0,
        },
        {
          y: toY,
          opacity: 1,
          duration: duration,
          delay: delay,
          ease: "power3.inOut",
        }
      );
    },
    fadeOutDown(el, fromY, toY, duration, delay) {
      this.gsap.fromTo(
        el,
        {
          y: fromY,
          opacity: 1,
        },
        {
          y: toY,
          opacity: 0,
          duration: duration,
          delay: delay,
          ease: "power3.inOut",
        }
      );
    },
  },
  watch: {
    getMenuOpened: function() {
      if (this.getMenuOpened) {
        this.gsap.to(this.$refs.menu, {
          duration: 1,
          css: { display: "block" },
        });

        this.gsap.fromTo(
          ".strip",
          {
            y: "-100%",
          },
          {
            duration: 1.5,
            y: "0",
            ease: "expo.inOut",
            stagger: 0.07,
          }
        );

        this.fadeInUp(".logo", 50, 0, 0.8, 0.3);

        this.gsap.fromTo(
          ".mobile-nav .nav-item__wrapper",
          {
            y: "100%",
          },
          {
            duration: 0.8,
            delay: 0.4,
            y: "0",
            ease: "power3.inOut",
            stagger: 0.05,
          }
        );

        this.fadeInUp(".mobile-nav .contact-info .title", 50, 0, 0.8, 0.5);
        this.fadeInUp(".mobile-nav .contact-info .phone", 50, 0, 0.8, 0.6);
        this.fadeInUp(".mobile-nav .contact-info .email", 50, 0, 0.8, 0.65);

        this.gsap.fromTo(
          ".mobile-nav .social",
          {
            y: 40,
            opacity: 0,
          },
          {
            duration: 0.8,
            y: "0",
            delay: 0.7,
            opacity: 1,
            ease: "power3.inOut",
            stagger: 0.02,
          }
        );
      } else {
        this.fadeOutDown(".mobile-nav .logo", 0, -40, 0.8, 0);

        this.gsap.fromTo(
          ".mobile-nav .nav-item__wrapper",
          {
            y: "0",
          },
          {
            duration: 0.8,
            y: "-100%",
            ease: "power3.inOut",
            stagger: 0.05,
          }
        );

        this.fadeOutDown(".mobile-nav .contact-info .title", 0, -40, 0.8, 0);
        this.fadeOutDown(".mobile-nav .contact-info .phone", 0, -40, 0.8, 0.1);
        this.fadeOutDown(".mobile-nav .contact-info .email", 0, -40, 0.8, 0.15);

        this.gsap.fromTo(
          ".mobile-nav .social",
          {
            y: 0,
            opacity: 1,
          },
          {
            duration: 0.8,
            y: -40,
            delay: 0.2,
            opacity: 0,
            ease: "power3.inOut",
            stagger: 0.02,
          }
        );

        this.gsap.fromTo(
          ".strip",
          {
            y: "0",
          },
          {
            duration: 1.5,
            y: "100%",
            delay: 0.3,
            ease: "expo.inOut",
            stagger: 0.07,
          }
        );

        this.gsap.to(this.$refs.menu, {
          duration: 1.7,
          css: { display: "none" },
        });
      }
    },
    $route: function(to, from) {
      if (to.name !== from.name) {
        this.toggleMenu(false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile-nav {
  display: none;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;

  .strips {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    .strip {
      background-color: $secondary-color;
      height: 100%;
    }
  }

  .menu-container {
    padding: 0 4.5vw;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;

    .content {
      position: relative;
      width: 100%;
      height: 100%;

      .logo {
        position: absolute;
        top: 30px;
      }

      .main-nav {
        position: absolute;
        top: 150px;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(12, 1fr);

        .nav-item {
          grid-column: 1 / 13;
          text-align: right;
          margin-bottom: 1rem;
          overflow-y: clip;

          &__wrapper {
            display: flex;
            justify-content: flex-end;
            align-items: baseline;

            .title {
              font-size: clamp(2rem, 8vw, 6rem);
              font-style: italic;
              text-transform: uppercase;
              line-height: 1;
              margin-right: 0.5rem;

              a {
                color: $white;
                transition: 500ms;
                font-weight: 800;

                &:hover,
                &:active,
                &.router-link-exact-active {
                  color: $primary-color;
                  letter-spacing: 4px;
                }
              }
            }
          }
        }
      }

      .contact-info {
        position: absolute;
        bottom: 160px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .title {
          font-size: 1.5rem;
          color: $white;
          margin-bottom: 1rem;
        }

        .details {
          display: flex;
          justify-content: space-between;

          .address {
            display: flex;
            align-items: flex-start;
            margin-right: 3.8rem;

            img {
              width: 18px;
              height: 20px;
              margin-right: 0.8rem;
            }
            p {
              font-size: 0.875rem;
              line-height: 1.35rem;
              color: $text-light;
              font-weight: 600;
            }
          }

          .contact {
            a {
              display: flex;
              align-items: center;
              color: $white;
              font-size: 0.875rem;
              line-height: 1.35rem;
              color: $text-light;
              font-weight: 600;
              position: relative;

              &::before {
                content: "";
                width: 0;
                height: 1px;
                background-color: $text-light;
                position: absolute;
                bottom: -3px;
                right: 0;
                transition: 500ms cubic-bezier(0.3, 1, 0.7, 1);
              }

              &:hover,
              &:focus {
                &::before {
                  width: 60%;
                }
              }

              &.phone {
                margin-bottom: 1rem;
              }

              img {
                width: 20px;
                margin-right: 0.8rem;
              }
            }
          }
        }
      }

      .bottom-line {
        width: 100%;
        position: absolute;
        bottom: 10px;

        &__wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .credits {
            p {
              color: $white;
              font-size: 0.875rem;
            }
          }

          .socials {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .social {
              width: 18px;
              height: 18px;
              margin: 1rem;
              position: relative;

              &:first-of-type {
                margin-left: 0;
              }

              &:last-of-type {
                margin-right: 0;
              }

              &::before {
                content: "";
                width: 0;
                height: 1px;
                background-color: $text-light;
                position: absolute;
                bottom: -6px;
                left: 0;
                transition: 500ms cubic-bezier(0.3, 1, 0.7, 1);
              }

              &:hover,
              &:focus {
                &::before {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
