<template>
  <nav>
    <NavItem url="/about-us">About Us</NavItem>
    <NavItem url="/technology">Technology</NavItem>
    <!-- <NavItem url="/blog">Blog</NavItem> -->
    <NavItem url="/careers">Careers</NavItem>
    <router-link to="/contact">
      <CustomButton class="primary">
        Let's Talk
      </CustomButton>
    </router-link>
  </nav>
</template>

<script>
import NavItem from "@/components/layout/navigation/NavItem.vue";
import CustomButton from "@/components/utils/CustomButton.vue";

export default {
  name: "NavMenu",
  components: { NavItem, CustomButton },
};
</script>

<style lang="scss" scoped>
nav {
  display: flex;
  justify-content: space-around;
  align-items: center;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

@media screen and (min-width: 993px) {
  nav {
    display: flex;
  }
}

@media screen and (max-width: 992px) {
  nav {
    display: none;
  }
}
</style>
